<template>
  <section class="section mt-2 section--shadow-0">

    <div class="section__header">
      <div class="section__controls">

      </div>
    </div>

    <div class="section__body">
      <div class="table-responsive table-3-wrapper">
        <table class="table table-borderless">

          <thead class="table-3__head">
          <tr class="table-3__row">
            <th colspan="3" class="table-3__cell  table__hcell">
              <form method="get" @submit.prevent="fetchItems">
                <div class="field-advanced">
                  <div class="field-advanced__inner">
                    <div class="field-advanced__field-wrap">
                      <input v-model="filterForm.query" class="field  field--full-width  field--bg-grey  js-borderOnFocus" type="text" :placeholder="getTranslation('search')">
                    </div>

                    <div class="field-advanced__right">
                      <button class="btn btn-primary">
                        <svg class="btn__icon">
                          <use xlink:href="#icon__search"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </th>
            <th class="table-3__cell  table__hcell">
              <ServiceCategoryComponent :form-class="''" :form-label="''" v-model="filterForm.service_category_id"></ServiceCategoryComponent>
            </th>
            <th class="table-3__cell  table__hcell">
              <ServiceOptionComponent :form-class="''" :form-label="''" v-model="filterForm.service_option_id" :full="true"></ServiceOptionComponent>
            </th>
            <th class="table-3__cell  table__hcell">
              <CountrySelect
                  v-model="filterForm.destination_country_id"
                  form-label="destinationCountry"
                  :form-class="''"
                  :form-label="''"
              >
              </CountrySelect>
            </th>
            <th class="table-3__cell  table__hcell"></th>
            <th colspan="2" class="table-3__cell  table__hcell">
              <v-select
                  :options="[{id:1,title:getTranslation('isClientPaid')},{id:0,title:getTranslation('isClientNotPaid')}]"
                  :reduce="option=>option.id"
                  label="title"
                  :placeholder="getTranslation('selectFromList')"
                  v-model="filterForm.is_client_paid"
              >
              </v-select>
            </th>
            <th class="table-3__cell  table__hcell">
              <VSelect
                  :options="formOptions.statuses"
                  :reduce="option=>option.id"
                  label="title"
                  v-model="filterForm.status"
                  :placeholder="getTranslation('selectFromList')"
              />
            </th>
          </tr>
          <tr class="table-3__row">
            <th class="table-3__cell  table__hcell" style="width: 0.1%">#</th>
            <th class="table-3__cell  table__hcell" style="width: 2%">{{ getTranslation('code') }}</th>
            <th class="table-3__cell  table__hcell" style="width: 10%">{{ getTranslation('title') }}</th>
            <th class="table-3__cell  table__hcell" style="width: 5%">{{ getTranslation('serviceCategory') }}</th>
            <th class="table-3__cell  table__hcell" style="width: 5%">{{ getTranslation('serviceOptions') }}</th>
            <th class="table-3__cell  table__hcell" style="width: 5%">{{ getTranslation('destinationCountry') }}</th>
            <th class="table-3__cell  table__hcell" style="width: 5%">{{ getTranslation('shipmentDate') }}</th>
            <th class="table-3__cell  table__hcell text-capitalize" style="width: 4%">{{ getTranslation('payment') }}</th>
            <th class="table-3__cell  table__hcell" style="width: 3%"></th>
            <th class="table-3__cell  table__hcell" style="width: 3%">{{ getTranslation('status') }}</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(item ,key) in items" v-bind:key="item.id" class="table-3__row">
            <td class="table-3__cell">{{ item.id }}</td>
            <td class="table-3__cell"> {{ item.code }}</td>
            <td class="table-3__cell">
              <router-link :to="{name:'application.get',params:{id:item.id}}">{{ item.title }}</router-link>
            </td>
            <td class="table-3__cell"> {{ getTranslation(item.service_category.slug) }}</td>
            <td class="table-3__cell"> {{ item.by_land ? getTranslation(item.service_option.title.land) : getTranslation(item.service_option.title.air) }}</td>
            <td class="table-3__cell"> {{ item.destination_country.title }}</td>
            <td class="table-3__cell"> {{ item.shipment_date }}</td>
            <td class="table-3__cell">
              <span v-if="item.is_client_paid" class="text-success">{{ getTranslation('isClientPaid') }}</span>
              <span v-else class="text-danger">{{ getTranslation('isClientNotPaid') }}</span>
            </td>
            <td class="table-3__cell">
              <span class="font-weight-bold" v-if="item.selectedAppCompany">{{ item.selectedAppCompany.totalPrice }} {{ item.selectedAppCompany.currency }}</span>
            </td>
            <td class="table-3__cell">
              <span class="badge rounded-pill"
                    :class="{'badge-primary':item.status === 'CONFIRMED' || item.status==='AGENT_CONFIRMED','badge-success':item.status === 'NEW' || item.status==='AGENT_NEW','badge-warning':item.status === 'WAITING_PROVIDER'
                    || item.status==='AGENT_SELECTED','badge-danger':item.status === 'REJECTED' || item.status === 'AGENT_REJECTED' || item.status === 'AGENT_EXPIRED'}">
                {{ getTranslation('APPLICATION_' + item.status) }}
              </span>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>

  </section>
</template>

<script>
import ServiceCategoryComponent from "@/components/shared/ServiceCategoryComponent";
import ServiceOptionComponent from "@/components/shared/ServiceOptionComponent";
import CountrySelect from "@/components/shared/CountrySelect";

export default {
  name: "AppComponent",
  components: { CountrySelect, ServiceOptionComponent, ServiceCategoryComponent },
  props: {
    active: {
      default: false
    },
    itemId: {
      required: true
    },
  },
  data() {
    return {
      items: [],
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
      filterForm: {
        page: this.$route.query.page || 1
      },
      formOptions: {
        statuses: [],
        clientStatuses: []
      }
    }
  },
  created() {
    if ( this.active )
      this.fetchItems();

    this.fetchFormOptions();
  },
  watch: {
    'active'(val) {
      if ( val )
        this.fetchItems();
    },
    'filterForm.page'() {
      this.fetchItems();
    },
    'filterForm.service_category_id'() {
      this.fetchItems();
    },
    'filterForm.service_option_id'() {
      this.fetchItems();
    },
    'filterForm.is_client_paid'() {
      this.fetchItems();
    },
    'filterForm.status'() {
      this.fetchItems();
    }
  },
  methods: {
    fetchFormOptions() {
      this.axios.get(this.$urls.application.formOptions.url)
          .then(response => {
            let data = response.data.data;
            this.formOptions.statuses = data.statuses.map(item => {
              return {
                title: this.getTranslation('APPLICATION_' + item),
                id: item
              };
            });
          }).catch(() => {
      });
    },
    fetchItems() {

      this.axios.get(this.$urls.client.apps.url.replace(':id', this.itemId), { params: this.cleanObject({ ...this.filterForm }) })
          .then(response => {
            this.items = response.data.data.items;

            this.pagination = response.data.data.pagination;
          }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>

</style>